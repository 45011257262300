<template>
	<div class="header__mobile">
		<div class="header__mobile--top">
			<div class="header__mobile--logo">
				<div class="header__logo">
					<div class="header__logo--left">
						<a :href="'/' + $i18n.locale + '/'">
							<img src="../assets/img/logo.svg" alt="" />
						</a>
					</div>
					<div class="header__logo--right">
						<div class="header__logo--title">
							{{ $t('site.logo.title') }}
						</div>
						<div class="header__logo--subtitle">
							{{ $t('site.logo.desc') }}
						</div>
					</div>
				</div>
			</div>
			<div class="header__mobile--close">
				<div class="mobile__close--click" @click="mobileActive">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18 6L6 18M6 6l12 12" stroke="#0E1839" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
				</div>
			</div>
		</div>
		<div class="header__mobile--body">
			<div class="header__search--block">
				<div class="header__search--header">
					<form class="header__search--form" @submit.prevent="searchSubmit">
						<input type="text" :placeholder="$t('site.search')" name="query" v-model="searchText" />
						<button type="submit">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
						</button>
					</form>
				</div>
				<div class="header__search--body" v-if="searchApplicate">
					<template v-if="searchApplicate.data">
						<div class="search__result" v-for="item in searchApplicate.data" :key="item.id">
							<router-link :to="'/' + $i18n.locale + '/description/' + item.id">{{
								item.title
							}}</router-link>
						</div>
					</template>
					<div class="search__result" v-if="searchApplicate.data_error">
						<a>{{ searchApplicate.data_error }}</a>
					</div>
				</div>
			</div>

			<div class="header__mobile--menu">
				<ul class="menu">
					<li>
						<a href="/#about" @click="headerMenuClick">{{ $t('site.menu.about') }}</a>
					</li>
					<li>
						<a href="/#map" @click="headerMenuClick">{{ $t('site.menu.map') }}</a>
					</li>
					<li>
						<a href="/#news" @click="headerMenuClick">{{ $t('site.menu.news') }}</a>
					</li>
					<li>
						<a href="/#video" @click="headerMenuClick">{{ $t('site.menu.video') }}</a>
					</li>
					<li>
						<a href="/#faq" @click="headerMenuClick">{{ $t('site.menu.faq') }}</a>
					</li>
					<li>
						<a :href="'/' + $i18n.locale + '/contact'">{{ $t('site.menu.contact') }}</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { api } from '@/boot/axios'

export default {
	data: function () {
		return {
			mobileClick: true,
			activeMenu: false,

			searchClick: false,
			searchApplicate: null,
			searchText: '',
		}
	},
	methods: {
		searchSubmit(evt) {
			this.searchApplicate = null
			const formData = new FormData(evt.target)

			api
				.get('search', {
					params: {
						query: this.searchText,
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.searchApplicate = response.data
					}
				})
		},

		headerMenuClick(e) {
			if (e.target.hash && document.querySelector(e.target.hash)) {
				e.preventDefault()
				document.querySelector(e.target.hash).scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				})
			}
		},
		mobileActive() {
			this.mobileClick = false
			document.body.classList.remove('mobile__active')
		},
	},
}
</script>

<style scoped></style>
