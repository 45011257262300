<template>
	<header class="header__site">
		<div class="container">
			<div class="header__site--row">
				<div class="header__left">
					<div class="header__logo">
						<div class="header__logo--left">
							<a :href="'/' + $i18n.locale + '/'">
								<img src="../assets/img/logo.svg" alt="" />
							</a>
						</div>
						<div class="header__logo--right">
							<div class="header__logo--title">
								{{ $t('site.logo.title') }}
							</div>
							<div class="header__logo--subtitle">
								{{ $t('site.logo.desc') }}
							</div>
						</div>
					</div>
				</div>
				<div class="header__right">
					<div class="header__menu">
						<ul class="menu">
							<li>
								<a href="/#about" @click="headerMenuClick">{{ $t('site.menu.about') }}</a>
							</li>
							<li>
								<a href="/#map" @click="headerMenuClick">{{ $t('site.menu.map') }}</a>
							</li>
							<li>
								<a href="/#news" @click="headerMenuClick">{{ $t('site.menu.news') }}</a>
							</li>
							<li>
								<a href="/#video" @click="headerMenuClick">{{ $t('site.menu.video') }}</a>
							</li>
						</ul>
					</div>

					<div class="header__search" v-bind:class="{ 'hidden-search': !searchClick }">
						<transition name="slide-fade">
							<div class="header__search--block" v-if="searchClick">
								<div class="header__search--header">
									<form class="header__search--form" @submit.prevent="searchSubmit">
										<input
											type="text"
											:placeholder="$t('site.search')"
											name="query"
											v-model="searchText"
										/>
										<button type="submit">
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</button>
									</form>
								</div>
								<div class="header__search--body" v-if="searchApplicate">
									<template v-if="searchApplicate.data">
										<div class="search__result" v-for="item in searchApplicate.data" :key="item.id">
											<router-link :to="'/' + $i18n.locale + '/description/' + item.id">{{
												item.title
											}}</router-link>
										</div>
									</template>
									<div class="search__result" v-if="searchApplicate.data_error">
										<a>{{ searchApplicate.data_error }}</a>
									</div>
								</div>
							</div>
						</transition>
						<a class="search__btn header__serch--click" @click="searchClick = !searchClick">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
						</a>
					</div>

					<div class="header__bvi">
						<a class="bvi-class bvi-open">
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.833 10S4.167 3.333 10 3.333 19.167 10 19.167 10 15.833 16.667 10 16.667.833 10 .833 10z" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
						</a>
					</div>

					<div class="header__lang">
						<div class="header__lang--top">
							<div class="header__lang--text">
								{{ langOn }}
							</div>
							<div class="header__lang--arrow">
								<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.5 9l6 6 6-6" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
							</div>
						</div>

						<div class="header__lang--list">
							<ul>
								<li :class="{ 'header__lang--active': this.$i18n.locale == 'kz' }">
									<a href="#" @click.prevent="setLocale('kz')">Қазақ</a>
								</li>
								<li :class="{ 'header__lang--active': this.$i18n.locale == 'ru' }">
									<a href="#" @click.prevent="setLocale('ru')">Русский</a>
								</li>
							</ul>
						</div>
					</div>
					<div class="header__burger">
						<div class="header__burger--click" @click="mobileActive">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3 6h18M3 12h18M3 18h18" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import isvek from '../assets/js/bvi/dist/js/bvi'
import '../assets/js/bvi/dist/css/bvi.min.css'
import { api } from '@/boot/axios'

const USER_ACTIVITY_THROTTLER_TIME = process.env.VUE_APP_USER_ACTIVITY_THROTTLER_TIME || 30000

export default {
	data: function () {
		return {
			searchClick: false,
			searchApplicate: null,
			searchText: '',

			mobileClick: false,
			loginClick: false,
			userActivityTimeout: null,
		}
	},
	computed: {
		langOn: function () {
			var lang = this.$i18n.locale
			if (lang == 'ru') {
				lang = 'Рус'
			} else if (lang == 'kz') {
				lang = 'Қаз'
			} else if (lang == 'en') {
				lang = 'Eng'
			}
			return lang
		},
	},
	methods: {
		headerMenuClick(e) {
			if (e.target.hash && document.querySelector(e.target.hash)) {
				e.preventDefault()
				document.querySelector(e.target.hash).scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				})
			}
		},
		logout() {
			if (localStorage.getItem('token')) {
				this.$store.dispatch('logout')
				this.$router.push('/ru/login')
			}
		},
		mobileActive() {
			this.mobileClick = true
			document.body.classList.add('mobile__active')
		},
		headerLogin: function () {
			this.loginClick = !this.loginClick
		},
		setLocale(locale) {
			if (this.$i18n.locale != locale) {
				this.$i18n.locale = locale
				this.$router.push({
					params: { lang: locale },
				})
			}
		},
		searchSubmit(evt) {
			this.searchApplicate = null
			const formData = new FormData(evt.target)

			api
				.get('search', {
					params: {
						query: this.searchText,
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.searchApplicate = response.data
					}
				})
		},
		resetUserActivityTimeout() {
			if (this.userActivityTimeout) clearTimeout(this.userActivityTimeout)
			this.userActivityTimeout = setTimeout(() => {
				this.logout()
			}, USER_ACTIVITY_THROTTLER_TIME)
		},
		activateActivityTracker() {
			window.addEventListener('mousemove', this.resetUserActivityTimeout)
			window.addEventListener('scroll', this.resetUserActivityTimeout)
			window.addEventListener('keydown', this.resetUserActivityTimeout)
			window.addEventListener('resize', this.resetUserActivityTimeout)

			this.resetUserActivityTimeout()
		},
		resetActivityTracker() {
			window.removeEventListener('mousemove', this.resetUserActivityTimeout)
			window.removeEventListener('scroll', this.resetUserActivityTimeout)
			window.removeEventListener('keydown', this.resetUserActivityTimeout)
			window.removeEventListener('resize', this.resetUserActivityTimeout)

			clearTimeout(this.userActivityTimeout)
		},
	},
	beforeDestroy() {
		this.resetActivityTracker()
	},
	mounted() {
		this.activateActivityTracker()
		let langClass = 'ru-RU'
		if (this.$i18n.locale == 'ru') {
			langClass = 'ru-RU'
		} else if (this.$i18n.locale == 'kz') {
			langClass = 'kz-KZ'
		} else if (this.$i18n.locale == 'en') {
			langClass = 'en-US'
		}

		let bviClass = new isvek.Bvi({
			lang: langClass,
		})
	},
	created() {
		const onClickOutside = (e) => {
			;(this.searchClick = this.$el.contains(e.target) && this.searchClick),
				(this.searchApplicate = null)
		}
		document.addEventListener('click', onClickOutside)
		this.$on('hook:beforeDestroy', () => document.removeEventListener('click', onClickOutside))
	},
}
</script>
