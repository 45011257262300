<template>
	<div id="app" v-bind:class="{ 'home-page': classPage, mobile__active: mobileStatus }">
		<!--  class="wrapper"  -->
		<router-view />
	</div>
</template>

<script>
export default {
	name: 'App',
	metaInfo() {
		return {
			title: this.$t('site.logo.title'),
			meta: [
				{
					vmid: 'description',
					name: 'description',
					content: this.$t('site.logo.desc'),
				},
				{
					vmid: 'og:title',
					property: 'og:title',
					content: this.$t('site.logo.title'),
				},
				{
					vmid: 'og:description',
					property: 'og:description',
					content: this.$t('site.logo.desc'),
				},
			],
		}
	},
	data() {
		return {
			namePagePer: false,
			mobileStatus: false,
		}
	},
	computed: {
		classPage: function () {
			return this.namePageFunc()
		},
	},
	beforeMount() {
		this.$store.dispatch('loadFromLocal')
	},
	methods: {
		namePageFunc() {
			if (this.$route.name != 'Home') {
				return (this.namePagePer = false)
			} else {
				return (this.namePagePer = true)
			}
		},
		mobileActive(status) {
			this.mobileStatus = status
		},
	},
	head: {
		// To use "this" in the component, it is necessary to return the object through a function
		title: function () {
			return {
				inner: this.$t('site.logo.title'),
			}
		},
	},
	watch: {},
}
</script>

<style>
@import './assets/css/vue-multiselect.min.css';
@import './assets/css/v-tooltip.css';

@import './assets/css/vuetify.css';
@import './assets/css/bootstrap.min.css';

@import './assets/css/style.css';
@import './assets/css/media.css';
</style>
