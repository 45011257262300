var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header__notify--wrapper",class:{ 'header__notify--active': _vm.notifyClick }},[_c('div',{staticClass:"header__notify"},[_c('div',{staticClass:"header__notify--btn",on:{"click":_vm.headerNotify}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M18 8A6 6 0 106 8c0 7-3 9-3 9h18s-3-2-3-9zM13.73 21a1.999 1.999 0 01-3.46 0M2 8c0-2.2.7-4.3 2-6M22 8a10 10 0 00-2-6","stroke":"#5ABB5E","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})]),(_vm.notificationsHeader && _vm.notificationsHeader.length)?_c('div',{staticClass:"header__notify--number"},[_vm._v(" "+_vm._s(_vm.notificationsHeader.length)+" ")]):_vm._e()])]),(_vm.notificationsHeader && _vm.notificationsHeader.length)?_c('div',{staticClass:"header__notify--absol"},[_c('div',{staticClass:"header__notify--list"},[_c('ul',_vm._l((_vm.notificationsHeader),function(item,index){return _c('li',{key:index},[(JSON.parse(item.data).message.link)?_c('a',{staticClass:"pointer",attrs:{"target":"_blank"},on:{"click":function($event){_vm.readSet(index, JSON.parse(item.data).message.link)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( 'notification.' + JSON.parse(item.data).message.model + '.' + JSON.parse(item.data).message.status + '.text', { link: JSON.parse(item.data).message.link }, ))+" ")])]):(
							_vm.$te(
								'notification.' +
									JSON.parse(item.data).message.model +
									'.' +
									JSON.parse(item.data).message.status +
									'.href',
							)
						)?_c('a',{staticClass:"pointer",on:{"click":function($event){_vm.readSet(
								index,
								'/' +
									_vm.$i18n.locale +
									_vm.$t(
										'notification.' +
											JSON.parse(item.data).message.model +
											'.' +
											JSON.parse(item.data).message.status +
											'.href',
										{ id: JSON.parse(item.data).id },
									),
							)}}},[_vm._v(" "+_vm._s(_vm.$t( 'notification.' + JSON.parse(item.data).message.model + '.' + JSON.parse(item.data).message.status + '.text', ))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t( 'notification.' + JSON.parse(item.data).message.model + '.' + JSON.parse(item.data).message.status + '.text', ))+" ")])])}),0)])]):_c('div',{staticClass:"header__notify--absol"},[_c('div',{staticClass:"header__notify--list"},[_vm._v(" "+_vm._s(_vm.$t('notification.not_new'))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }