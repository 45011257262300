<template>
	<div class="header__notify--wrapper" v-bind:class="{ 'header__notify--active': notifyClick }">
		<div class="header__notify">
			<div class="header__notify--btn" @click="headerNotify">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18 8A6 6 0 106 8c0 7-3 9-3 9h18s-3-2-3-9zM13.73 21a1.999 1.999 0 01-3.46 0M2 8c0-2.2.7-4.3 2-6M22 8a10 10 0 00-2-6" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
				<div
					class="header__notify--number"
					v-if="notificationsHeader && notificationsHeader.length"
				>
					{{ notificationsHeader.length }}
				</div>
			</div>
		</div>
		<div class="header__notify--absol" v-if="notificationsHeader && notificationsHeader.length">
			<div class="header__notify--list">
				<ul>
					<li v-for="(item, index) in notificationsHeader" :key="index">
						<a
							v-if="JSON.parse(item.data).message.link"
							@click="readSet(index, JSON.parse(item.data).message.link)"
							target="_blank"
							class="pointer"
						>
							<span>
								{{
									$t(
										'notification.' +
											JSON.parse(item.data).message.model +
											'.' +
											JSON.parse(item.data).message.status +
											'.text',
										{ link: JSON.parse(item.data).message.link },
									)
								}}
							</span>
						</a>
						<a
							v-else-if="
								$te(
									'notification.' +
										JSON.parse(item.data).message.model +
										'.' +
										JSON.parse(item.data).message.status +
										'.href',
								)
							"
							class="pointer"
							@click="
								readSet(
									index,
									'/' +
										$i18n.locale +
										$t(
											'notification.' +
												JSON.parse(item.data).message.model +
												'.' +
												JSON.parse(item.data).message.status +
												'.href',
											{ id: JSON.parse(item.data).id },
										),
								)
							"
						>
							{{
								$t(
									'notification.' +
										JSON.parse(item.data).message.model +
										'.' +
										JSON.parse(item.data).message.status +
										'.text',
								)
							}}
						</a>
						<span v-else>
							{{
								$t(
									'notification.' +
										JSON.parse(item.data).message.model +
										'.' +
										JSON.parse(item.data).message.status +
										'.text',
								)
							}}
						</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="header__notify--absol" v-else>
			<div class="header__notify--list">
				{{ $t('notification.not_new') }}
			</div>
		</div>
	</div>
</template>

<script>
import { api } from '@/boot/axios'

export default {
	data() {
		return {
			notificationsHeader: [],
			notifyClick: false,
		}
	},
	methods: {
		getData() {
			this.notificationsHeader = []
			// api
			//   .get('notifications', {})
			//   .then((response) => {
			//     this.notificationsHeader = response.data;
			//   })
			//   .catch((error) => {
			//   });
		},
		readSet(index, link) {
			api
				.post('notifications/read', {
					index: index,
				})
				.then((response) => {
					this.notifyClick = false
					this.getData()
					// this.$router.push({ path: link });
					// window.location.href = link;
					window.open(link, '_blank')
				})
				.catch(() => {})
		},
		headerNotify() {
			this.notifyClick = !this.notifyClick
		},
	},
	mounted() {
		this.getData()
	},
}
</script>

<style></style>
