import Vue from 'vue'
import VueRouter from 'vue-router'
import VueHead from 'vue-head'

import { routes } from './routes'

Vue.use(VueHead, {
	separator: '|',
	complement: 'Мониторинг рыб и других водных животных',
})
Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		window.scrollTo(0, 0)
	},
})

export default router
