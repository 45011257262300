import Vue from 'vue'
import axios from 'axios'
import i18n from '../i18n'

Vue.prototype.$axios = axios

/*

if (localStorage.user) {
  const user = localStorage.user;
}

*/

const urlApi = process.env.VUE_APP_BASE_URL

const api = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
})

api.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem('token')
		if (token) config.headers.common['Authorization'] = `${token}`

		const selected_lang_locale = localStorage.getItem('selected_lang_locale')
		if (selected_lang_locale) config.headers.common['Accept-Language'] = `${selected_lang_locale}`

		return config
	},
	(error) => {
		return Promise.reject(error)
	},
)

api.interceptors.response.use(
	(response) => {
		return response
	},
	async (error) => {
		if (error.response.status === 401) {
			const refresh_token = localStorage.getItem('refresh_token')
			const refresh_time = localStorage.getItem('refresh_time')

			if (
				refresh_token &&
				Math.floor((new Date().getTime() - +refresh_time) / 1000) <
					+process.env.VUE_APP_REFRESH_TOKEN_TIME
			) {
				const res = await api
					.post('/user/refreshToken', {
						refresh_token,
					})
					.catch(() => {
						window.location = '/' + i18n.locale + '/login'
					})
				if (res.data.token && !res.data.token.error) {
					localStorage.setItem('token', 'Bearer ' + res.data.token.access_token)
					localStorage.setItem('refresh_token', res.data.token.refresh_token)
					localStorage.setItem('refresh_time', new Date().getTime())
					return await api
						.request({
							...error.config,
							headers: {
								...error.config.headers,
								Authorization: `Bearer ${res.data.token.access_token}`,
							},
						})
						.catch(() => {
							window.location = '/' + i18n.locale + '/login'
						})
				}
			}

			localStorage.removeItem('token')
			localStorage.removeItem('refresh_token')
			localStorage.removeItem('refresh_time')

			window.location = '/' + i18n.locale + '/login'
		}
		throw error
	},
)

const apiSecond = axios.create({
	baseURL: process.env.VUE_APP_API_MAIN,
})

apiSecond.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem('token')
		if (token) config.headers.common['Authorization'] = `${token}`

		const selected_lang_locale = localStorage.getItem('selected_lang_locale')
		if (selected_lang_locale) config.headers.common['Accept-Language'] = `${selected_lang_locale}`

		return config
	},
	(error) => {
		return Promise.reject(error)
	},
)

apiSecond.interceptors.response.use(
	(response) => {
		return response
	},
	async (error) => {
		if (error.response.status === 401) {
			const refresh_token = localStorage.getItem('refresh_token')
			const refresh_time = localStorage.getItem('refresh_time')

			if (
				refresh_token &&
				Math.floor((new Date().getTime() - +refresh_time) / 1000) <
					+process.env.VUE_APP_REFRESH_TOKEN_TIME
			) {
				const res = await api.post('/user/refreshToken', {
					refresh_token,
				})
				if (res.data.token && !res.data.token.error) {
					localStorage.setItem('token', 'Bearer ' + res.data.token.access_token)
					localStorage.setItem('refresh_token', res.data.token.refresh_token)
					localStorage.setItem('refresh_time', new Date().getTime())

					return await api.request({
						...error.config,
						headers: {
							...error.config.headers,
							Authorization: `Bearer ${res.data.token.access_token}`,
						},
					})
				}
			}
			localStorage.removeItem('token')
			localStorage.removeItem('refresh_token')
			localStorage.removeItem('refresh_time')
		}
		throw error
	},
)

export { axios, api, urlApi, apiSecond }
