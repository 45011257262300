<template>
	<div class="page__preloader">
		<div class="form__block--preloader">
			<div class="form__preloader--circle">
				<div class="preloader__circle">
					<svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="36.5" cy="36" r="24" stroke="#FFF7E1" stroke-width="2"/><path d="M16.16 23A24.208 24.208 0 0012 36.605C12 50.078 22.969 61 36.5 61S61 50.078 61 36.605c0-1.736-.182-3.43-.528-5.064" stroke="#52A5FC" stroke-width="4" stroke-linecap="round"/></svg>
				</div>
			</div>
			<div
				class="form__preloader--text form__block--line form__messages form__messages--error"
				v-if="message && message.status"
			>
				<label class="form__block--input">
					<span class="form__block--title">
						{{ message.text }}
					</span>
				</label>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		message: {
			type: Object,
			default: null,
		},
	},
}
</script>

<style scoped></style>
