<template>
	<header>
		<div class="container">
			<div class="header__row">
				<div class="header__left">
					<div class="header__logo">
						<div class="header__logo--left">
							<a :href="'/' + $i18n.locale + '/account/'">
								<img src="../assets/img/logo.svg" alt="" />
							</a>
						</div>
						<div class="header__logo--right">
							<div class="header__logo--title">
								{{ $t('site.logo.title') }}
							</div>
							<div class="header__logo--subtitle">
								{{ $t('site.logo.desc') }}
							</div>
						</div>
					</div>
				</div>
				<div class="header__right">
					<div class="header__lang">
						<HeaderLang></HeaderLang>
					</div>

					<HeaderBell></HeaderBell>

					<div
						class="header__login"
						v-bind:class="{ 'header__login--active': loginClick }"
						v-if="$store.state.userObject"
					>
						<div class="header__login--block">
							<div class="header__login--reletive" @click="headerLogin">
								<div class="header__login--left">
									<div class="header__login--info">
										<div class="header__login--name">
											{{ $store.state.userObject.first_name }}
											{{ $store.state.userObject.last_name }}
										</div>
										<div class="header__login--position">
											{{ $t('roles.' + $store.state.userObject.role.description) }}
										</div>
									</div>
								</div>
								<div class="header__login--right">
									<img src="../assets/img/icon-arrowdown.svg" />
								</div>
								<div class="header__login--icon">
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2M12 11a4 4 0 100-8 4 4 0 000 8z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
								</div>
							</div>
							<div class="login__absol">
								<div class="login__absol--list">
									<ul class="menu">
										<li v-if="this.$store.state.userObject.role.name == 'declarer'">
											<router-link :to="'/' + $i18n.locale + '/account/info'">
												<span class="login__list--left">
													<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15 15.75v-1.5a3 3 0 00-3-3H6a3 3 0 00-3 3v1.5M9 8.25a3 3 0 100-6 3 3 0 000 6z" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</span>
												<span class="login__list--right">
													{{ $t('admin.login.my_profile') }}
												</span>
											</router-link>
										</li>
										<li>
											<router-link :to="'/' + $i18n.locale + '/account/settings'">
												<span class="login__list--left">
													<img src="../assets/img/icon-settings.svg" />
												</span>
												<span class="login__list--right">
													{{ $t('admin.login.settings') }}
												</span>
											</router-link>
										</li>
										<li>
											<a @click="logout()">
												<span class="login__list--left">
													<img src="../assets/img/icon-logout.svg" />
												</span>
												<span class="login__list--right">
													{{ $t('admin.login.logout') }}
												</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="header__login" v-else>
						<div class="header__login--block">
							<div class="header__login--reletive header__login--circle" @click="headerLogin">
								<div class="header__login--icon">
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2M12 11a4 4 0 100-8 4 4 0 000 8z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import HeaderLang from '@/components/HeaderLang'
import HeaderBell from '@/components/HeaderBell'

const USER_ACTIVITY_THROTTLER_TIME = process.env.VUE_APP_USER_ACTIVITY_THROTTLER_TIME || 600000

export default {
	components: {
		HeaderLang,
		HeaderBell,
	},
	data: function () {
		return {
			mobileClick: false,
			loginClick: false,
			userActivityTimeout: null,
		}
	},
	computed: {},
	methods: {
		logout() {
			if (localStorage.getItem('token')) {
				this.$store.dispatch('logout')
				localStorage.removeItem('USER_INFO_LOAD_TIME')
				// location.reload();
				this.$router.push({ path: '/' + this.$i18n.locale + '/login' })
			}
		},
		resetUserActivityTimeout() {
			if (this.userActivityTimeout) clearTimeout(this.userActivityTimeout)
			this.userActivityTimeout = setTimeout(() => {
				this.logout()
			}, USER_ACTIVITY_THROTTLER_TIME)
		},
		activateActivityTracker() {
			window.addEventListener('mousemove', this.resetUserActivityTimeout)
			window.addEventListener('scroll', this.resetUserActivityTimeout)
			window.addEventListener('keydown', this.resetUserActivityTimeout)
			window.addEventListener('resize', this.resetUserActivityTimeout)

			this.resetUserActivityTimeout()
		},
		resetActivityTracker() {
			window.removeEventListener('mousemove', this.resetUserActivityTimeout)
			window.removeEventListener('scroll', this.resetUserActivityTimeout)
			window.removeEventListener('keydown', this.resetUserActivityTimeout)
			window.removeEventListener('resize', this.resetUserActivityTimeout)

			clearTimeout(this.userActivityTimeout)
		},
		mobileActive() {
			this.mobileClick = true
			document.body.classList.add('mobile__active')
		},
		headerLogin: function () {
			this.loginClick = !this.loginClick
		},
	},
	beforeDestroy() {
		this.resetActivityTracker()
	},
	mounted() {
		this.activateActivityTracker()
	},
}
</script>
