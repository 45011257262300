import { apiSecond } from '@/boot/axios'

export default {
	state: {
		fishingPermits: [],
		fishingPermitsMeta: {},
	},
	getters: {
		_getFishingPermits(state) {
			return state.fishingPermits
		},
		_getFishingPermitsMeta(state) {
			return state.fishingPermitsMeta
		},
	},
	mutations: {
		SET_FISHING_PERMITS(state, payload) {
			state.fishingPermits = payload
		},
		SET_FISHING_PERMITS_META(state, payload) {
			state.fishingPermitsMeta = payload
		},
	},
	actions: {
		async _fetchFishingPermits({ commit }, options) {
			apiSecond
				.get('/v2/r13-moosr3-services', {
					params: { page: options?.page || 1, per_page: options?.itemsPerPage || 5 },
				})
				.then((response) => {
					commit('SET_FISHING_PERMITS', response.data.data || [])
					commit('SET_FISHING_PERMITS_META', response.data.meta || {})
				})
				.catch((error) => {
					console.log(error)
				})
		},
		async _reloadPermissions({ dispatch }) {
			await apiSecond.post('/v2/update/status/elcisn')
			dispatch('_fetchFishingPermits')
		},
	},
}
